import React from 'react'
import Box from './Box'
import PropTypes from 'prop-types'
import style from './BoxCatalog.module.css'

const BoxCatalog = (props) => {
  const boxList = props.json.map(s =>
    <Box key={s.name} name={s.name} url={s.url} href={s.href} />
  );
  
  return (
    <div className={style.boxCatalog}>
      {boxList}
    </div>
  )
}

BoxCatalog.propTypes = {
  json: PropTypes.array.isRequired
}

export default BoxCatalog
