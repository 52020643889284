import { Route, Switch } from 'react-router-dom'
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ContactPage from './pages/ContactPage'
import NotFoundPage from './pages/NotFoundPage'
import Header from './components/Header'
import Footer from './components/Footer'
import { useRef } from 'react'

function App() {
  const myRef = useRef(null)

  const scroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

  return (
    <div className="main-container">
      <Header scroll={scroll} />
      <Switch>
        <Route path='/' exact>
          <HomePage ref={myRef} />
        </Route>
        <Route path='/about'>
          <AboutPage />
        </Route>
        <Route path='/contact'>
          <ContactPage />
        </Route>
        <Route path='*'>
          <NotFoundPage />
        </Route>
      </Switch>
      <Footer />
    </div>
  );  
}

export default App;
