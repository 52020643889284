import React, { Component } from 'react'
import style from './Project.module.css'

export class Project extends Component { 
  constructor(props) {
    super(props);
    this.visit = this.visit.bind(this);
  }

  noImg(e) {
    e.target.style.display = 'none';
  }

  visit() {
    window.location.href = this.props.url;
  }

  render() {
    return (
      <div className={style.project}>
        <div>
          <img onError={this.noImg} src={this.props.img} alt='thumbnail' />
        </div>
        <div>{this.props.name}</div>
        <div>{this.props.desc}</div>
        <div>
          <button onClick={this.visit}>View</button>
        </div>
      </div>
    )
  }
}

export default Project