import PropTypes from 'prop-types'
import Project from './Project'
import React, { useState } from 'react';
import { useRef } from 'react'
import projects from '../json/projects'
import style from './CatalogSection.module.css'

const CatalogSection = React.forwardRef((props, ref) => {
  const clsName = 'section ' + props.color;
  const sortRef = useRef(null);
  const filterRef = useRef(null);
  
  const [projectList, setProjects] = useState(projects);

  function filter(list, str) {
    const newProjects = [];
    for (let i = 0; i < list.length; i++) {
      const projectTitle = list[i].name.toLowerCase();
      if (projectTitle.includes(str.toLowerCase())) {
        newProjects.push(list[i]);
      }
    }
    return newProjects;
  }

  function sort(list, mode) {
    switch (mode) {
      case "new":
        return list.sort((p1, p2) => getTimestamp(p2.date.split('/')) - getTimestamp(p1.date.split('/')));
      case "old":
        return list.sort((p1, p2) => getTimestamp(p1.date.split('/')) - getTimestamp(p2.date.split('/')));
      case "alpha":
        return list.sort((p1, p2) => p1.name.localeCompare(p2.name));
      default:
        return [];
    }
  }

  function getTimestamp(date) {
    return 30 * parseInt(date[0]) + parseInt(date[1]) + 365 * parseInt(date[2]);
  }

  function updateProjects() {
    setProjects(sort(filter(projects, filterRef.current.value), sortRef.current.value));
  }

  return (
    <div ref={ref} className={clsName}>
      <div>
        <h1>{props.title}</h1>
        <div>
          <div className={style.filter}>
            <div>
              <h2 className='sub-section-title'>My Projects</h2>
            </div>
            <div>
              <span>Sort</span>
              <div>
                <select onChange={updateProjects} ref={sortRef} name='order' id='search-order'>
                    <option value='new'>Newest</option>
                    <option value='old'>Old</option>
                    <option value='alpha'>A-Z</option>
                </select>
              </div>
              <input onChange={updateProjects} ref={filterRef} id="search-filter" placeholder="Search" />
            </div>
          </div>
          <div className={style.projectCatalog}>
            {projectList.map(p => 
              <Project key={p.name} name={p.name} desc={p.desc} img={p.img} date={p.date} url={p.url} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

CatalogSection.defaultProps = {
  color: 'yellow',
  title: 'Not Enough! Show Me More!',
}

CatalogSection.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string
}

export default CatalogSection