import React from 'react'
import Section from '../components/Section'
import { useHistory } from 'react-router-dom';

const NotFoundPage = () => {
  const history = useHistory();

  function back() {
    history.push('/');
  }

  const content = () => {
    return (
      <div className='not-found'>
        <h1>404</h1>
        <h4>Not Found</h4>
        <button onClick={back}>Return Home</button>
      </div>
    )
  }

  return (
    <>
     <Section title='' content={content} /> 
    </>
  )
}

export default NotFoundPage
