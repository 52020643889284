import React from 'react'
import Section from '../components/Section'
import CatalogSection from '../components/CatalogSection'
import FeaturedProject from '../components/FeaturedProject'
import projects from '../json/projects'
import style from '../pages/HomePage.module.css'


const HomePage = React.forwardRef((props, ref) => {
  const featuredProjectList = projects.map(p =>
    <FeaturedProject key={p.name} name={p.name} detail={p.detail} img={p.img} date={p.date} url={p.url} />
  );

  const sectionTitles = [
    'Who Are You?',
    'What Have You Done So Far?',
  ];
  
  const intro = () => {
    return (
      <React.Fragment>
        <div>
          <img style={{maxWidth: '30rem'}} src={'../images/hey.png'} alt='profile' />
        </div>
        <div style={{maxWidth: '700px'}}>
          <p>
            Hello, I am Jacky, a student majoring in Computer Science. I enjoy making programs to assist me in different parts of my life. Let me introduce you to some of my past projects to get a taste of who I am!
          </p>
        </div>
      </React.Fragment>
    )
  }

  const featured = () => {
    return (
      <div className={style.featuredCatalog}>
        {featuredProjectList}
      </div>
    )
  }

  return (
    <React.Fragment>
      <Section content={intro} title={sectionTitles[0]} />
      <Section content={featured} title={sectionTitles[1]} color='red' />
      <CatalogSection ref={ref} />
    </React.Fragment>
  )
})
 
export default HomePage
