import Project from './Project'
import style from './FeaturedProject.module.css'

export class FeaturedProject extends Project {
  noImg(e) {
    e.target.style.display = 'none';
  }

  render() {
    return (
      <div className={style.project}>
        <div>
          <img onError={this.noImg} src={this.props.img} alt='thumbnail' />
        </div>
        <div>
          <h2>{this.props.name}</h2>
          <p>{this.props.detail}</p>
          <button onClick={this.visit}>View</button>
        </div>
      </div>
    )
  }
}

export default FeaturedProject
