import React from 'react'
import Section from '../components/Section'
import BoxCatalog from '../components/BoxCatalog'
import style from './AboutPage.module.css'
import skills from '../json/skills.json'

const AboutPage = props => {
  function noImg(e) {
    e.target.style.display = 'none';
  }

  const content = () => {
    return (
      <div>
        <div className={style.bio}>
          <div>
            <div>
              <div>
                <img onError={noImg} src='https://scontent-sjc3-1.xx.fbcdn.net/v/t1.6435-9/57485715_2668378776511827_25651112354775040_n.jpg?_nc_cat=106&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=vVN1ZaDJYxIAX_zcwo0&_nc_ht=scontent-sjc3-1.xx&oh=7d553217a6e904a18f74575831957a12&oe=60D8B232' alt='profile' />
              </div>
            </div>
            <div>
              <p>Hello, I am Jacky, a student majoring in Computer Science. I enjoy making programs to assist me in different parts of my life.
                I have experience in many different areas of Computer Science but my strength currently lies in web development.</p>
            </div>
          </div>
          <div>
            <div>
              <h2 className='sub-section-title'>Skills</h2>         
            </div>
            <div>
              <BoxCatalog json={skills} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Section title='About Me' content={content} />
    </React.Fragment>
  )
}

export default AboutPage
