import PropTypes from 'prop-types'
import style from './Box.module.css'

const Box = (props) => {
  function noImg(e) {
    e.target.style.display = 'none';
  }

  function toURL() {
    if (props.href !== '') {
      window.open(props.href);
    }
  }

  return (
    <div onClick={toURL} className={style.box}>
      <div>
        <img onError={noImg} src={props.url} alt='thumbnail' />
      </div>
      <span>{props.name}</span>
    </div>
  )
}

Box.defaultProps = {
  name: '',
  url: '',
  href: '',
}

Box.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  href: PropTypes.string
}

export default Box
