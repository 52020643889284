import React from 'react'
import style from './Footer.module.css'

const Footer = () => {
  return (
    <div className={style.footer}>
      <span>wlay.me © 2021 - All Rights Reserved</span>
    </div>
  )
}

export default Footer