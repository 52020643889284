import React from 'react'
import PropTypes from 'prop-types'

const Section = React.forwardRef((props, ref) => {
  const clsName = 'section ' + props.color;

  return (
    <div ref={ref} className={clsName}>
      <div>
        {props.title !== '' ? <h1>{props.title}</h1> : ''}
        {props.content()}
      </div>
    </div>
  )
})

Section.defaultProps = {
  color: '',
  title: '',
  content: () => {}
}

Section.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string
}

export default Section
