import React from 'react'
import Section from '../components/Section'
import BoxCatalog from '../components/BoxCatalog'
import contacts from '../json/contacts.json'

const ContactPage = () => {
  const content = () => {
    return (
      <div style={{width: '100%'}}>
        <BoxCatalog json={contacts} />
      </div>
    )
  }

  return (
    <React.Fragment>
      <Section title='Ways To Find Me' content={content} />
    </React.Fragment>
  )
}

export default ContactPage
