import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

import style from './Header.module.css'

const Header = props => {
  const location = useLocation()
  const history = useHistory()

  function toggleMenu() {
    const checkbox = document.getElementById('menuToggle');
    if (checkbox.checked) {
      document.body.setAttribute('class', '');
      checkbox.checked = false;
    } else {
      document.body.setAttribute('class', 'noScroll');
      checkbox.checked = true;
    }
  }

  function onCheckboxChange() {
    const checkbox = document.getElementById('menuToggle');
    if (checkbox.checked) {
      document.body.setAttribute('class', 'noScroll');
    } else {
      document.body.setAttribute('class', '');
    }
  }

  function toProjects() {
    if (location.pathname === '/') {
      props.scroll();
    } else {
      const promise = new Promise((resolve, reject) => {
        history.push('/');
        resolve();
      })
      promise.then(() => {
        props.scroll();
      });
    }
  }

  function mobileToProjects() {
    toggleMenu();
    toProjects();
  }

  return (
    <div className={style.header}>
      <div>
        <div><span><Link to='/'>WLAY</Link></span></div>
        <div className={style.hamburger}>
          <input onChange={onCheckboxChange} id='menuToggle' type='checkbox' />
          <div></div>
          <div></div>
          <div></div>
          <div>
            <ul>
              <li><span onClick={mobileToProjects}>Projects</span></li>
              <li><Link onClick={toggleMenu} to='/about'>About</Link></li>
              <li><Link onClick={toggleMenu} to='/contact'>Contacts</Link></li>
            </ul>
          </div>
        </div>
        <div>
          <nav>
            <ul>
              <li><span onClick={toProjects}>Projects</span></li>
              <li><Link to='/about'>About</Link></li>
              <li><Link to='/contact'>Contacts</Link></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

Header.protoTypes = {
  scroll: PropTypes.func.isRequired
} 

export default Header
